import * as React from "react"
import { personalInfo } from "../data/resume"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faFileAlt } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

const IndexPage = () => {
  return (
    <>
      <main className="container min-h-screen flex flex-col px-12">
        <title>Beatriz Arantes</title>
        <div className="m-auto flex flex-col pt-6 pb-24">
          <div className="flex flex-col">
            <span className="font-thin text-5xl sm:text-6xl md:text-8xl">
              {personalInfo.firstName}
            </span>
            <span className="font-bold text-5xl sm:text-6xl md:text-8xl md:-mt-4">{personalInfo.lastName}</span>
          </div>
          <ul className="flex flex-row space-x-6 mt-4">
            <li><Link to="/resume"><FontAwesomeIcon icon={faFileAlt} /> <span className="text-gray-500 underline">currículo</span></Link></li>
            <li><a href={`https://www.linkedin.com/in/${personalInfo.linkedin}/`}><FontAwesomeIcon icon={faLinkedin} /> <span className="text-gray-500 underline">{personalInfo.linkedin}</span></a></li>
          </ul>
        </div>
      </main>
    </>
  )
}

export default IndexPage
